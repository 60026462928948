.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-xs {
  width: 104px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-s {
  width: 216px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-sm {
  width: 216px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-m {
  width: 328px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-md {
  width: 328px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-l {
  width: 440px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-lg {
  width: 440px;
}
.ant-form > div:not(.ant-pro-form-light-filter) .pro-field-xl {
  width: 552px;
}

.ant-pro-field-date-picker-light .ant-calendar-picker,
.ant-pro-field-date-picker-light .ant-picker {
  position: absolute;
  width: 80px;
  height: 28px;
  overflow: hidden;
  visibility: hidden;
}

.ant-pro-core-field-label {
  display: inline-block;
  height: 30px;
  padding: 0 4px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 2px;
  cursor: pointer;
}
.ant-pro-core-field-label-active {
  padding: 0 12px;
  background-color: rgba(0, 0, 0, 0.04);
}
.ant-pro-core-field-label-active.ant-pro-core-field-label-allow-clear:hover:not(.ant-pro-core-field-label-disabled) .ant-pro-core-field-label-arrow {
  display: none;
}
.ant-pro-core-field-label-active.ant-pro-core-field-label-allow-clear:hover:not(.ant-pro-core-field-label-disabled) .ant-pro-core-field-label-close {
  display: inline-block;
}
.ant-pro-core-field-label-icon {
  margin-top: -2px;
  margin-left: 4px;
  padding: 1px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  vertical-align: middle;
}
.ant-pro-core-field-label-icon.ant-pro-core-field-label-close {
  display: none;
  margin-top: -4px;
  padding: 3px;
  color: #fff;
  font-size: 8px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.ant-pro-core-field-label-icon.ant-pro-core-field-label-close:hover {
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-pro-core-field-label-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-pro-core-field-label-disabled .ant-pro-core-field-label-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-pro-core-field-label-small {
  height: 24px;
  padding: 0 4px;
  font-size: 12px;
  line-height: 24px;
}
.ant-pro-core-field-label-small.ant-pro-core-field-label-active {
  padding: 0 8px;
}
.ant-pro-core-field-label-small .ant-pro-core-field-label-icon {
  padding: 0;
}
.ant-pro-core-field-label-small .ant-pro-core-field-label-close {
  margin-top: -2px;
  padding: 3px;
  font-size: 6px;
}
.ant-pro-core-field-label-bordered {
  height: 32px;
  padding: 0 12px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-pro-core-field-label-bordered.ant-pro-core-field-label-small {
  height: 24px;
  padding: 0 8px;
}
.ant-pro-core-field-label-bordered.ant-pro-core-field-label-active {
  background-color: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-field-index-column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
}
.ant-pro-field-index-column-border {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background-color: #314659;
  border-radius: 9px;
}
.ant-pro-field-index-column-border.top-three {
  background-color: #979797;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-field-dropdown {
  width: auto;
}

.ant-pro-field-select-light-select .ant-select {
  position: absolute;
  width: 153px;
  height: 28px;
  visibility: hidden;
}
.ant-pro-field-select-light-select .ant-select-selector {
  height: 28px;
}
.ant-pro-field-select-light-select.ant-pro-field-select-light-select-searchable .ant-select {
  width: 200px;
}
.ant-pro-field-select-light-select.ant-pro-field-select-light-select-searchable .ant-select-selector {
  height: 28px;
}

.ant-pro-field-checkbox-vertical .ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}

.ant-pro-field-radio-vertical .ant-radio-wrapper {
  display: block;
  margin-right: 0;
}

.ant-pro-field-light-wrapper-collapse-label {
  padding: 1;
}
.ant-pro-field-light-wrapper-container .ant-form-item {
  margin-bottom: 0;
}

.ant-pro-core-field-dropdown-label {
  cursor: pointer;
}
.ant-pro-core-field-dropdown-overlay {
  min-width: 200px;
  margin-top: 4px;
  background-color: #fff;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-pro-core-field-dropdown-content {
  padding: 16px;
}

.ant-pro-core-dropdown-footer {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 8px;
  border-top: 1px solid #f0f0f0;
}

.ant-pro-form-light-filter {
  line-height: 30px;
}
.ant-pro-form-light-filter::before {
  display: block;
  height: 0;
  visibility: hidden;
  content: '.';
}
.ant-pro-form-light-filter-small {
  line-height: 1.5715;
}
.ant-pro-form-light-filter-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-right: -4px;
}
.ant-pro-form-light-filter-item {
  margin-top: 8px;
}
.ant-pro-form-light-filter-item:not(:last-child) {
  margin-right: 8px;
}
.ant-pro-form-light-filter-formlabel {
  margin-bottom: 2px;
}
.ant-pro-form-light-filter-line {
  min-width: 198px;
}
.ant-pro-form-light-filter-line .ant-form-item {
  flex-direction: column;
  margin-bottom: 0;
}
.ant-pro-form-light-filter-line:not(:first-child) {
  margin-top: 16px;
  margin-bottom: 8px;
}
.ant-pro-form-light-filter .ant-form-item {
  margin-bottom: 0;
}
.ant-pro-form-light-filter-collapse-icon {
  width: 32px;
  height: 32px;
  line-height: 35px;
  border-radius: 50%;
}
.ant-pro-form-light-filter-effective .ant-pro-form-light-filter-collapse-icon {
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-pro-steps-form-container {
  width: max-content;
  max-width: 100%;
  margin: auto;
}
.ant-pro-steps-form-steps-container {
  max-width: 1160px;
  margin: auto;
}
.ant-pro-steps-form-step {
  display: none;
  margin-top: 32px;
}
.ant-pro-steps-form-step-active {
  display: block;
}
.ant-pro-steps-form-step > form {
  width: max-content;
  max-width: 100%;
}
.ant-pro-steps-form-step .ant-form-vertical > .ant-form-item {
  max-width: max-content;
}
.ant-pro-steps-form-step .ant-form-vertical > .ant-form-item .ant-form-item-control {
  max-width: max-content;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-form-group-title {
  margin-bottom: 16px;
  font-weight: bold;
}
.ant-pro-form-group-container {
  flex-wrap: wrap;
}

